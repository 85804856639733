<template>
  <div
    v-show="!editMode"
    id="schema-form-data-viewer"
    :class="{ hasSchemaViewerData }"
  >
    <!-- Disclaimer ------------------------------------------------------------------------------->
    <div
      v-if="disclaimer.length"
      class="alert alert-info"
      :class="schemaViewerData ? 'text-left' : 'text-center'"
      role="alert"
    >
      <b>{{ disclaimer }}</b>
    </div>

    <template v-if="!loading">
      <!-- Header --------------------------------------------------------------------------------->
      <div v-if="headerText || !hasSchemaViewerData" class="schema-form-data-viewer-top">
        <h5 v-if="headerText">
          {{ headerText }}
        </h5>
        <p v-if="!hasSchemaViewerData" v-html="subTitle" />
      </div>

      <div class="schema-form-data-viewer-bottom">
        <!-- Non-Boolean Form Data ---------------------------------------------------------------->
        <div class="viewer__data">
          <div :class="!hasSchemaViewerData ? 'row' : ''">
            <div
              v-for="(item, index) in nonBooleanFormData"
              :key="`${item.id}${index}`"
              class="display-content-margin w-100"
            >
              <!-- String =======================================================================-->
              <div v-if="dataType(index, item) === 'string'">
                <h6 class="margin-left-1-75">
                  {{ displayKey(index) }}
                </h6>
                <p class="margin-left-1-75">
                  {{ item }}
                </p>
              </div>

              <!-- Registered Agent =============================================================-->
              <!-- Privacy Level ------------------------------------------------------------------>
              <div
                v-else-if="dataType(index, item) === 'registered-agent'"
                :class="highlightClasses(index, item.address)"
                class="privacy-level  pb-2"
              >
                <h6>
                  Registered Agent
                </h6>
                <template v-if="usingRAWhenNotHired(item) && !hasSchemaViewerData && showEditLink">
                  <a class="invalid-data-link margin-left-1-75" href="#" @click="$emit('edit-data')">
                    Invalid/Missing Data
                  </a>
                </template>
                <template v-else>
                  <p v-if="item.name" class="font-weight-bold px-4">
                    {{ item.name }}
                  </p>

                  <div v-if="item.contact_first_name || item.contact_last_name || item.title">
                    <p class="font-weight-bold px-4">
                      {{ item?.contact_first_name }} {{ item?.contact_last_name }}
                      <span v-if="item.title">
                        - {{ item.title }}
                      </span>
                    </p>
                  </div>

                  <!-- Signature image ------------------------------------------------------------>
                  <img
                    v-if="item.signature"
                    :ref="item.name"
                    :src="item.signature"
                    alt="signature"
                    class="half-width-max"
                  >
                  <order-item-address
                    v-if="isAddress(item.address)"
                    :address="item.address"
                    :attention="item.attention || ''"
                    :address-on-one-line="true"
                    class="px-4"
                  />
                </template>
              </div>

              <!-- Person =======================================================================-->
              <div
                v-else-if="dataType(index, item) === 'person'"
                class="display-contents"
              >
                <div v-if="showOfficialArray(index, item)">
                  <h6 class="margin-left-1-75">
                    {{ officialRoleHeader(item) }}
                  </h6>

                  <div v-for="(official, officialIndex) in item"
                       :key="`${official.role}${officialIndex}`"
                  >
                    <!-- Privacy Level ------------------------------------------------------------>
                    <div
                      v-if="isOfficial(official)"
                      class="privacy-level mb-1 py-2"
                      :class="highlightClasses(index, official?.address)"
                    >
                      <p v-if="official?.first_name || official?.last_name"
                         class="font-weight-bold px-4"
                      >
                        {{ official?.first_name }} {{ official?.last_name }}
                      </p>

                      <p v-if="official?.company_name" class="font-weight-bold px-4">
                        {{ official.company_name }}
                      </p>

                      <p v-if="showAdditionalOfficialData && official?.phone_number" class="px-4">
                        {{ displayPhoneNumber(official.phone_number) }}
                      </p>

                      <p v-if="showAdditionalOfficialData && official?.email_address" class="px-4">
                        {{ official.email_address }}
                      </p>

                      <order-item-address
                        v-if="isAddress(official.address)"
                        :address="official.address"
                        :attention="item.attention || ''"
                        :address-on-one-line="true"
                        class="px-4"
                      />

                      <p v-if="showAdditionalOfficialData && official?.company_ownership_percentage > 0" class="px-4">
                        Ownership Percentage: {{ official.company_ownership_percentage }}%
                      </p>
                    </div>
                  </div>
                </div>
                <!-- Privacy Level ---------------------------------------------------------------->
                <div
                  v-else-if="isOfficial(item) || index === 'filer' || index.includes('official.')"
                  class="privacy-level pb-2"
                  :class="highlightClasses(index, item?.address)"
                >
                  <h6>
                    {{ displayOfficialRole(item, index) }}
                  </h6>

                  <p v-if="Array.isArray(item) && item.length === 0" class="margin-left-1-75">
                    -
                  </p>

                  <template v-else>
                    <p v-if="item.first_name || item.last_name" class="font-weight-bold px-4">
                      {{ item?.first_name }} {{ item?.last_name }}
                    </p>

                    <p v-if="item?.name" class="px-4">
                      {{ item.name }}
                    </p>

                    <p v-if="item?.company_name" class="px-4">
                      {{ item.company_name }}
                    </p>

                    <p v-if="showAdditionalOfficialData && item?.email_address" class="px-4">
                      {{ item.email_address }}
                    </p>

                    <p v-if="showAdditionalOfficialData && item?.phone_number" class="px-4">
                      {{ displayPhoneNumber(item.phone_number) }}
                    </p>

                    <order-item-address
                      v-if="isAddress(item.address)"
                      :address="item.address"
                      :attention="item.attention || ''"
                      :address-on-one-line="true"
                      class="px-4"
                    />

                    <p v-else-if="isString(item?.address)">
                      {{ item.address }}
                    </p>

                    <p v-if="showAdditionalOfficialData && item?.company_ownership_percentage > 0" class="px-4">
                      Ownership Percentage: {{ item.company_ownership_percentage }}%
                    </p>
                  </template>
                </div>
              </div>

              <!-- Address ======================================================================-->
              <!-- Privacy Level ------------------------------------------------------------------>
              <div
                v-else-if="dataType(index, item) === 'address'"
                class="privacy-level margin-left-1-75 display-content-margin"
                :class="highlightClasses(index, item)"
              >
                <h6 class="m-0">
                  {{ displayKey(index) }}
                </h6>

                <template v-if="usingRAAddressWhenNotHired(item) && !hasSchemaViewerData && showEditLink">
                  <a class="invalid-data-link margin-left-1-75" href="#" @click="$emit('edit-data')">
                    Invalid/Missing Data
                  </a>
                </template>
                <template v-else>
                  <order-item-address
                    v-if="isAddress(item)"
                    :address="item"
                    :attention="item.attention || ''"
                    :address-on-one-line="true"
                    class="privacy-details"
                  />

                  <p v-else-if="isString(item?.address)" class="privacy-details">
                    {{ item.address }}
                  </p>
                </template>
              </div>

              <!-- Object =======================================================================-->
              <div
                v-else-if="dataType(null, item) === 'object'"
                class="margin-left-1-75"
              >
                <h6>
                  {{ displayKey(index) }}
                </h6>

                <div v-for="(itemValue, itemIndex) in item" :key="`${itemValue}${itemIndex}`">
                  <p>
                    {{ itemValue }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- Boolean Form Data ================================================================-->
          <div :class="!hasSchemaViewerData ? 'row' : ''">
            <div
              v-for="(item, index) in booleanFormData"
              :key="`${item.id}${index}`"
              class="display-content-margin w-100"
            >
              <div class="col-md-12 mb-1">
                <b-form-checkbox
                  v-model="booleanFormData[index]"
                  class="float-left"
                  :disabled="true"
                >
                  {{ displayKey(index) }}
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>

        <!-- Active Legend ======================================================================-->
        <div class="viewer__icon-legend">
          <div v-show="isAnyUnprotected" class="unprotected-address">
            This address may become publicly available.
          </div>
          <div v-show="isAnyProtected" class="protected-address">
            This address is protected.
          </div>
          <div v-show="isAnyInvalid" class="invalid-icon">
            More information required.
          </div>
        </div>
      </div>
    </template>

    <ct-centered-spinner v-else height="260px" />
  </div>
</template>


<script>
/*
NOTE: This component defaults to using ProcessedFormData from stagelineSchemaForm.store.js,
unless a schemaViewerData prop is passed in. Only case where schemaViewerData is not passed in is
the VerifyCheckout slide which shows the data from the PDF and an option to go back to the pdf to edit
*/
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'
import axiosClient from '../../http'
import * as DOMPurify from 'dompurify'
import {
  isAddress, displayKey, isOfficial, isString,
  displayPhoneNumber, dataType, trimDuplicates,
  filterBooleanFields, filterNonBooleanFields, isManager,
  metaSuggestionRAAddress,
} from '@/components/SchemaForm/schemaViewerHelper'

export default {
  name: 'SchemaFormDataViewer',
  components: {
    CtCenteredSpinner:  () => import('@/components/shared/CtCenteredSpinner.vue'),
    OrderItemAddress:   () => import('@/components/shared/OrderItemAddress.vue'),
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    headerText: {
      type: String,
      default: 'Company Formation',
    },
    disclaimer: {
      type: String,
      default: '',
    },
    schemaViewerData: {
      type: Object,
      default: null,
    },
    form: {
      type: [Object, Array],
      default: null,
    },
    showAdditionalOfficialData: {
      type: Boolean,
      default: true,
    },
    showEditLink: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      loading: false,
      raProduct: {},
      formHasInvalidData: false,
      isAnyInvalid: false,
      isAnyProtected: false,
      isAnyUnprotected: false,
    }
  },
  computed: {
    ...mapGetters('checkout', [
      'formationProductInCart',
      'raServiceActiveOrInCart',
      'raServiceInCart',
      'cartItems',
    ]),
    ...mapGetters('companies', [
      'activeRAServices',
      'formationFiling',
    ]),
    ...mapGetters('schema', [
      'schema',
      'invalidSchemaFields',
    ]),
    ...mapGetters('stagelineSchemaForm', [
      'processedFormData',
    ]),
    ...mapGetters('stageline', [
      'jurisdiction',
    ]),
    filerAddressIsProtected() {
      return this.formationProductInCart || this.formationFiling
    },
    hasSchemaViewerData() {
      return this.schemaViewerData && Object.keys(this.schemaViewerData).length
    },
    formData() {
      return this.hasSchemaViewerData ?
        trimDuplicates(this.schemaViewerData) :
        this.mergedProcessedFormData()
    },
    nonBooleanFormData() {
      return filterNonBooleanFields(this.formData)
    },
    booleanFormData() {
      return filterBooleanFields(this.formData)
    },
    containsManagementType() {
      return !!this.nonBooleanFormData?.management_type
    },
    managerManaged() {
      return this.nonBooleanFormData?.management_type === 'Manager Managed'
    },
    subTitle() {
      const data = this.formHasInvalidData ?
        `<p class="invalid-data-link">Review and fix errors below.</p>` :
        `<p>Review the information below.</p>`

      return DOMPurify.sanitize(data, {
        ALLOWED_TAGS: ['p'],
        ALLOWED_ATTR: ['class'],
      })
    },
  },
  watch: {
    formHasInvalidData(newValue, _oldValue) {
      if (newValue) this.setCheckoutDisabled(newValue)
    },
    raServiceInCart(newValue, _oldValue) {
      if (!newValue) this.$emit('refresh')
    },
    editMode() {
      this.resetHighlightClasses()
    },
  },
  async mounted() {
    this.loading = true
    if (!this.schemaViewerData) {
      await this.setCheckoutDisabled(false)
      // Always fetch processedFormData in case they've gone back and updated the document form
      await this.fetchProcessedFormData()
      if (!this.raServiceActiveOrInCart) await this.loadRA()
    }

    this.$nextTick(() => {
      this.loading = false
    })
  },
  methods: {
    // Methods from helper
    isAddress,
    displayKey,
    isOfficial,
    isString,
    displayPhoneNumber,
    dataType,

    ...mapActions('stagelineSchemaForm', ['fetchProcessedFormData']),
    ...mapActions('checkout', ['setCheckoutDisabled']),
    ...mapActions('stageline', ['goToSlide']),

    mergedProcessedFormData() {
      let data = {}
      data = _.merge(data, this.processedFormData?.__default__)
      data = _.merge(data, this.processedFormData?.__company__)
      data = _.merge(data, this.processedFormData?.__registration__)
      data = _.merge(data, this.processedFormData?.__misc__)

      if (this.formationProductInCart) {
        const formationCartItem = this.cartItems.find(ci => ci.display_info.product_category === 'form a company')

        if (formationCartItem) {
          const formationCartItemData = formationCartItem?.data?.default || formationCartItem?.data
          data = { ...data, ...formationCartItemData }
        }
      }

      return data
    },
    async loadRA() {
      const productRes = await axiosClient.get(`client/products/registered_agent_products`,
        { params: { include_address: true, jurisdiction_id: this.jurisdiction.id } }
      )

      if (productRes.data.result.length) this.raProduct = productRes.data.result[0]
    },
    usingRAWhenNotHired(item) {
      if (this.raServiceActiveOrInCart) return false
      const usingRAWhenNotHired = (item?.name === this.raProduct?.ra_name ||
        this.usingRAAddressWhenNotHired(item.address))

      this.formHasInvalidData = this.formHasInvalidData || usingRAWhenNotHired
      return usingRAWhenNotHired
    },
    usingRAAddressWhenNotHired(address) {
      if (this.raServiceActiveOrInCart) return false
      const usingRAAddressWhenNotHired = address?.line1 === this.raProduct?.line1 &&
        address?.line2 === this.raProduct?.line2

      this.formHasInvalidData = this.formHasInvalidData || usingRAAddressWhenNotHired
      return usingRAAddressWhenNotHired
    },
    resetHighlightClasses() {
      this.isAnyInvalid = false
      this.isAnyProtected = false
      this.isAnyUnprotected = false
    },
    highlightClasses(index, item) {
      const classes = []

      if (index === 'filer') {
        if (this.filerAddressIsProtected) {
          this.isAnyProtected = true
          classes.push('protected-address')
          classes.push('protected-area')
        } else {
          classes.push('unprotected-address')
          classes.push('unprotected-area')
        }
      } else if (isAddress(item)) {
        if (this.isInvalid(index)) {
          this.isAnyInvalid = true
          classes.push('invalid-area')
          classes.push('invalid-icon')
        } else {
          if (this.isProtected(item, index)) {
            this.isAnyProtected = true
            classes.push('protected-address')
            classes.push('protected-area')
            // explicit false check required
          } else if (this.isProtected(item, index) === false) {
            this.isAnyUnprotected = true
            classes.push('unprotected-address')
            classes.push('unprotected-area')
          }
        }
      }

      return classes
    },
    isInvalid(index) {
      return this.invalidSchemaFields.some(field => field.split('/').filter(x => x)[0].match(index))
    },
    isProtected(address, index) {
      if (!this.raServiceActiveOrInCart) return false
      let raAddress = this.formData?.registered_agent?.address

      if (
        typeof(raAddress) === 'undefined' ||
        typeof(address) === 'undefined' ||
        Object.values(address).every(value => value === undefined)
      ) {
        const raSuggestion = metaSuggestionRAAddress(index, this.form)

        if (raSuggestion?.line1) {
          raAddress = raSuggestion
        } else {
          return null
        }
      }

      const fields = [ 'line1', 'line2', 'city', 'state_province_region', 'zip_postal_code' ]
      for (const field of fields) {
        if (raAddress[field] !== address[field]) {
          return false
        }
      }
      return true
    },
    showOfficialArray(index, item) {
      return Array.isArray(item) &&
        isOfficial(item[0]) &&
        !(isManager(index) && this.containsManagementType && !this.managerManaged)
    },
    displayOfficialRole(item, index) {
      if (item.role) return item.role
      if (index.includes('official.')) return this.capitalizeFirstChar(index.split('official.')[1])
      return this.displayKey(index)
    },
    capitalizeFirstChar(string) {
      if (!string) return ''
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    officialRoleHeader(item) {
      return (item.length > 1 ?
        (item[0].role || 'Official') + '(s)' :
        (item[0].role || 'Official')
      ) || 'Contact(s)'
    },
  },
}
</script>

<style lang="scss" scoped>
// protected -------------------------------------------------------------------------------------->
$color-protected-light: $ct-ui-primary-light;
$color-protected-dark: #1D7270;
$icon-protected: "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='22' viewBox='0 0 18 22'%0A%3E%3Cg id='Group_692' data-name='Group 692' transform='translate(1 1)'%3E%3Cpath id='shield' d='M12,22s8-4,8-10V5L12,2,4,5v7C4,18,12,22,12,22Z' transform='translate(-4 -2)' fill='none' /%3E%3Cpath id='shield-2' data-name='shield' d='M12,22s8-4,8-10V5L12,2,4,5v7C4,18,12,22,12,22Z' transform='translate(-4 -2)' fill='none' stroke='%231d7270' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' /%3E%3Cpath id='check_5_' data-name='check (5)' d='M14.43,11.8,9,17,6.029,13.832' transform='translate(-2.029 -4.802)' fill='rgba(0,0,0,0)' stroke='%231d7270' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' /%3E%3C/g%3E%3C/svg%3E%0A";

// unprotected ------------------------------------------------------------------------------------>
$color-unprotected-light: #FFF2CC;
$color-unprotected-dark: #997405;
$icon-unprotected: "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='22' viewBox='0 0 18 22'%0A%3E%3Cg id='Group_580' data-name='Group 580' transform='translate(1 1)'%3E%3Cg id='Group_579' data-name='Group 579'%3E%3Cpath id='shield' d='M12,22s8-4,8-10V5L12,2,4,5v7C4,18,12,22,12,22Z' transform='translate(-4 -2)' fill='none' /%3E%3Cpath id='shield-2' data-name='shield' d='M12,22s8-4,8-10V5L12,2,4,5v7C4,18,12,22,12,22Z' transform='translate(-4 -2)' fill='none' stroke='%23997405' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' /%3E%3C/g%3E%3Cg id='x_4_' data-name='x (4)' transform='translate(5 6)'%3E%3Cline id='Line_16' data-name='Line 16' x1='6' y2='6' fill='none' stroke='%23997405' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' /%3E%3Cline id='Line_17' data-name='Line 17' x2='6' y2='6' fill='none' stroke='%23997405' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E";

// invalid ---------------------------------------------------------------------------------------->
$color-invalid-dark: $ct-ui-color-13;
$icon-invalid: "data:image/svg+xml,%3Csvg data-v-7bb683fc='' xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%23ca3b00' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-alert-circle'%3E%3Ccircle data-v-7bb683fc='' cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline data-v-7bb683fc='' x1='12' y1='8' x2='12' y2='12'%3E%3C/line%3E%3Cline data-v-7bb683fc='' x1='12' y1='16' x2='12.01' y2='16'%3E%3C/line%3E%3C/svg%3E";

$address-width: 65%;

@mixin highlight-icon(
  $content-icon,
  $top: -1.875em,
  $left: auto,
  $right: 19.375em,
  $mask-top: 0,
  $mask-left: -0.3125em,
  $mask-width: 1.25em,
  $mask-height: 1.625em,
  $mask-border-radius: 0 0 100% 0,
) {
  &::before {
    position: absolute;
    content: '';
    top: $mask-top;
    left: $mask-left;
    width: $mask-width;
    height: $mask-height;
    border-radius: $mask-border-radius;
    background: white;
  }
  &::after {
    position: absolute;
    top: $top;
    left: $left;
    right: $right;
    width: 1.0em;
    height: 1.25em;
    content: url($content-icon);
  }
}

@mixin privacy-text($content-text, $top: -3.0em, $left: auto, $right: 0, $width: 21.0em) {
  position: absolute;
  top: $top;
  left: $left;
  right: $right;
  width: $width;
  height: 3.5em;
  content: $content-text;
  font-size: 0.875em;
  font-weight: 400;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}

#schema-form-data-viewer {
  text-align: left;
  position: relative;
  padding-top: 0;

  hr {
    margin-bottom: 0;
  }

  .display-contents {
    display: contents;
  }

  .half-width-max {
    max-width: 50%;
  }

  .schema-form-data-viewer-top {
    h5 {
      margin-top: 0;
    }

    p {
      font-size: 0.75em;
      color: grey;
    }
  }

  .schema-form-data-viewer-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    .viewer__data {
      flex-grow: 1;
      max-width: 600px;
      h6 {
        margin-top: 0.5em;
        margin-bottom: 0.25em;
      }
      p {
        margin-bottom: 0;
      }
    }
    .viewer__icon-legend {
      width:325px;
      font-size: 0.875em;
      font-weight: 400;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-left: 3px;
      > * {
        position: relative;
        margin-bottom: 7px;
        padding-left: 16px !important;
        width: 100%;
        &::after {
          top: 0 !important;
        }
      }
    }
  }
}

#schema-form-data-viewer:not(.hasSchemaViewerData) {
  border-radius: 7px;
  box-shadow: 1px 2px 5px grey;

  .invalid-data-link {
    color: red;
  }

  .schema-form-data-viewer-top {
    padding: 1em 1.5em 0;
  }

  .schema-form-data-viewer-bottom {
    padding: 0 1.5em 1em;
    max-height: 50vh;
    overflow: scroll;

    h6 {
      font-weight: 800;
    }
  }
  .display-content-margin {
    margin: 0.5em 0;
  }
}
#schema-form-data-viewer.hasSchemaViewerData {
  hr {
    margin-left: 1em;
  }

  .schema-form-data-viewer-top {
    padding: 1.5em 1em 0;

    h5 {
      font-weight: 800;
    }
  }

  .schema-form-data-viewer-bottom {
    h6 {
      font-weight: 800;
    }
  }
  .display-content-margin {
    margin: 1.5em 0;
  }
}

// Dynamic Data Styles =============================================================================

.officials-container {
  width: $address-width;
}

.margin-left-1-75 {
  margin-left: 1.75em;
}

// Privacy Level -----------------------------------------------------------------------------------

.privacy-level {
  //width: $address-width !important;
  position: relative;
  border-radius: 0.375em;
  h6 {
    padding: 0.625em 0 0 1.5em;
  }
}

.privacy-details {
  padding: 0.125em 1.8125em 0.625em 1.5em;
}

.privacy {
  border-style: solid;
  border-width: 0.3125em;
}

// Icons -------------------------------------------------------------------------------------------

.protected-address {
  @include highlight-icon(
    $content-icon: $icon-protected,
    $top: -0.125em,
    $left: -0.6875em,
    $right: auto,
  );
}

.unprotected-address {
  @include highlight-icon(
    $content-icon: $icon-unprotected,
    $top: -0.125em,
    $left: -0.6875em,
    $right: auto,
  );
}

.invalid-icon {
  position: relative;
  @include highlight-icon(
    $content-icon: $icon-invalid,
    $top: -0.3em,
    $left: -0.7em,
    $right: auto,
    $mask-top: -2px,
    $mask-left: -0.3125em,
    $mask-width: 1.15em,
    $mask-height: 1.325em,
    $mask-border-radius: 0 0 100% 0,
  );
}

// Areas -------------------------------------------------------------------------------------------

.protected-area {
  background: $color-protected-light;
  border-left: 0.3125em solid $color-protected-dark;
  border-color: $color-protected-dark;
}

.unprotected-area {
  background: $color-unprotected-light;
  border-left: 0.3125em solid $color-unprotected-dark;
  border-color: $color-unprotected-dark;
}

.invalid-area {
  //::before { content: none; }
  border-left: 0.3125em solid $color-invalid-dark;
  border-top: 2px solid $color-invalid-dark;
  border-right: 2px solid $color-invalid-dark;
  border-bottom: 2px solid $color-invalid-dark;
}

.officials-container,
.privacy-level {
  width: 100%;
}
</style>
